import React from "react";
import "../assets/styles/Home.css";
import "../assets/styles/mediaqueris/HomeMedia.css";
import WhoWeAre from "../pages/WhoWeAre";
import Testimonial from "./Testimonial";
import ServicesHome from "./ServicesHome";
import Counter from "./Counter";
import Catlogue from "./Catlogue";
import HighLights from "./HighLights";

function Home() {
  return (
    <>
      <div className="home-slider-bg">
        <h1 className="">
          ABHITA AGRO
          <br />
          INDUSTRIES SUPPLIES <br /> THE BEST QUALITY <br /> PRODUCTS
        </h1>
      </div>
      <WhoWeAre />
      {/* <HighLights /> */}
      <ServicesHome />
      <Testimonial />
      <Catlogue />
      <Counter />
    </>
  );
}

export default Home;
