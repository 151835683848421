import React from "react";
import "../assets/styles/Catlogue.css";
function Catlogue() {
  return (
    <>
      <div className="catlogue_section">
        <div className="catlogue_section_outer">
          <div className="catlogueFirst">
            <a href="https://onedrive.live.com/?authkey=%21ALmVrx9zURRly7A&id=6518F385BC065C0F%21295&cid=6518F385BC065C0F&parId=root&parQt=sharedby&o=OneUp">
              Company Profile
            </a>
          </div>
          <p>Click here ..</p>
        </div>
      </div>
    </>
  );
}

export default Catlogue;
