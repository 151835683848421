import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { IMAGES } from "../utils/index";

import "../assets/styles/Media.css";
import "../assets/styles/mediaqueris/MediaMedia.css";
import { MdClose } from "react-icons/md";

const imgUrls = [
  { img: IMAGES.Expo3 },
  { img: IMAGES.Expo2 },
  { img: IMAGES.Expo1 },
  { img: IMAGES.Expo4 },
  { img: IMAGES.Expo5 },
  { img: IMAGES.Expo6 },
  { img: IMAGES.Expo7 },
  { img: IMAGES.Expo8 },
  { img: IMAGES.Expo9 },
  { img: IMAGES.Expo10 },
  { img: IMAGES.Expo11 },
  { img: IMAGES.Expo12 },
  { img: IMAGES.Expo13 },
  { img: IMAGES.Expo14 },
  { img: IMAGES.Expo15 },
  { img: IMAGES.Expo16 },
  { img: IMAGES.Expo17 },
  { img: IMAGES.Expo18 },
  { img: IMAGES.Expo19 },
  { img: IMAGES.Expo20 },
  { img: IMAGES.Expo21 },
  { img: IMAGES.Expo22 },
  { img: IMAGES.Expo23 },
  { img: IMAGES.Expo24 },
  { img: IMAGES.Expo25 },
  { img: IMAGES.Expo26 },
  { img: IMAGES.Expo27 },
  { img: IMAGES.Expo28 },
  { img: IMAGES.Expo29 },
  { img: IMAGES.Expo30 },
];

const Blog = () => {
  return (
    <>
      <div className="media-section">
        <section className="media-tab-bg">
          <div className="media-bg-text">
            <h3>Blog</h3>
          </div>
        </section>

        <div className="media-wrapper">
          <h3 className="media-heading">Abhita Expo - 2024</h3>
          <div className="container">
            <div>
              <div className="row">
                <Gallery>
                  {imgUrls.map((item, index) => (
                    <div className="col-xl-3 col-lg-4 col-md-6  col-sm-12">
                      <Item
                        original={item.img}
                        thumbnail={item.img}
                        width="800"
                        height="600"
                      >
                        {({ ref, open }) => (
                          <div className="media-img-col-wrapper">
                            <div className="media-img-col">
                              <img
                                ref={ref}
                                onClick={open}
                                src={item.img}
                                alt={`Image ${index + 1}`}
                              />
                            </div>
                          </div>
                        )}
                      </Item>
                    </div>
                  ))}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
