const IMAGES = {
  abhitaAgroWhiteLogo: require("../assets/images/AbhitaAgrowhiteLogo.png"),
  abhitaAgroColorLogo: require("../assets/images/AbhitaAgroLogo.png"),
  shape: require("../assets/images/shape.png"),
  whoweare: require("../assets/images/who_we_are.jpg"),
  whowearesmall: require("../assets/images/who-we-are-small.jpg"),
  leafBg: require("../assets/images/leaves-removebg-preview.png"),

  //About us
  abouttext: require("../assets/images/aboutus.jpg"),
  aboutwcuRightColImg: require("../assets/images/col-bgimage-5.jpg"),

  advantage: require("../assets/images/Slider-1.jpg"),

  RiceBullet: require("../assets/images/rice_bullet.png"),

  fruit: require("../assets/images/icon/fruit.png"),
  vegetableIcon: require("../assets/images/icon/vegetable-icon.png"),
  bakeryIcon: require("../assets/images/icon/bakery-icon.png"),
  ourservice: require("../assets/images/icon/our-service.png"),
  export: require("../assets/images/icon/export.png"),
  agriculture: require("../assets/images/icon/agriculture.png"),
  drone: require("../assets/images/icon/drone.png"),

  flower: require("../assets/images/flower.png"),

  // services
  //  how we work
  payment: require("../assets/images/services/protection.png"),
  fruitBg: require("../assets/images/services/fruit-black.jpg"),
  fruits: require("../assets/images/services/fruits.jpg"),
  bakery: require("../assets/images/services/bakery.png"),
  vegetables: require("../assets/images/services/vegetables.png"),
  bathroom: require("../assets/images/services/bathroom.jpg"),
  meat: require("../assets/images/services/meat.png"),
  pulse: require("../assets/images/services/pulses.png"),
  tissue: require("../assets/images/services/tissue.png"),
  seedDivision: require("../assets/images/services/seedDivision.png"),
  importExport: require("../assets/images/services/importExport.jpg"),
  droneDivision: require("../assets/images/services/droneDivision.png"),
  contractFarming: require("../assets/images/services/contractFarming.jpg"),

  //media
  img1: require("../assets/images/media/img1.JPG"),
  img6: require("../assets/images/media/img6.JPG"),
  img7: require("../assets/images/media/img7.JPG"),
  img9: require("../assets/images/media/img9.JPG"),
  img10: require("../assets/images/media/img10.JPG"),
  img11: require("../assets/images/media/img11.JPG"),
  img14: require("../assets/images/media/img14.JPG"),
  img16: require("../assets/images/media/img16.JPG"),
  img18: require("../assets/images/media/img18.JPG"),
  img19: require("../assets/images/media/img19.JPG"),
  img3: require("../assets/images/media/img3.JPG"),
  img4: require("../assets/images/media/img4.JPG"),

  package1: require("../assets/images/media/Package1.jpg"),
  package2: require("../assets/images/media/Package2.jpg"),
  package4: require("../assets/images/media/Package4.jpg"),
  package5: require("../assets/images/media/Package5.jpg"),
  package6: require("../assets/images/media/Package6.jpg"),
  package7: require("../assets/images/media/Package7.jpg"),
  package8: require("../assets/images/media/Package8.jpg"),
  package11: require("../assets/images/media/Package11.jpg"),
  package12: require("../assets/images/media/Package12.jpg"),
  package13: require("../assets/images/media/Package13.jpg"),
  // Fruit
  fruit1: require("../assets/images/fruits/santri1.jpeg"),
  fruit2: require("../assets/images/fruits/santri2.jpeg"),
  fruit3: require("../assets/images/fruits/santri3.jpeg"),
  fruit5: require("../assets/images/fruits/santri5.jpeg"),
  fruit6: require("../assets/images/fruits/santri6.jpeg"),
  fruit7: require("../assets/images/fruits/santri7.jpeg"),
  fruit8: require("../assets/images/fruits/santri8.jpeg"),
  fruit9: require("../assets/images/fruits/santri9.jpeg"),
  fruit10: require("../assets/images/fruits/santri10.jpeg"),
  orange: require("../assets/images/fruits/orange.png"),

  Expo1: require("../assets/images/media/Expo1.jpeg"),
  Expo2: require("../assets/images/media/Expo2.jpeg"),
  Expo3: require("../assets/images/media/Expo3.jpeg"),
  Expo4: require("../assets/images/media/Expo4.jpeg"),
  Expo5: require("../assets/images/media/Expo5.jpeg"),
  Expo6: require("../assets/images/media/Expo6.jpeg"),
  Expo7: require("../assets/images/media/Expo7.jpeg"),
  Expo8: require("../assets/images/media/Expo8.jpeg"),
  Expo9: require("../assets/images/media/Expo9.jpeg"),
  Expo10: require("../assets/images/media/Expo10.jpeg"),
  Expo11: require("../assets/images/media/Expo11.jpeg"),
  Expo12: require("../assets/images/media/Expo12.jpeg"),
  Expo13: require("../assets/images/media/Expo13.jpeg"),
  Expo14: require("../assets/images/media/Expo14.jpeg"),
  Expo15: require("../assets/images/media/Expo15.jpeg"),
  Expo16: require("../assets/images/media/Expo16.jpeg"),
  Expo17: require("../assets/images/media/Expo17.jpeg"),
  Expo18: require("../assets/images/media/Expo18.jpeg"),
  Expo19: require("../assets/images/media/Expo19.jpeg"),
  Expo20: require("../assets/images/media/Expo20.jpeg"),
  Expo21: require("../assets/images/media/Expo21.jpeg"),
  Expo22: require("../assets/images/media/Expo22.jpeg"),
  Expo23: require("../assets/images/media/Expo23.jpeg"),
  Expo24: require("../assets/images/media/Expo24.jpeg"),
  Expo25: require("../assets/images/media/Expo25.jpeg"),
  Expo26: require("../assets/images/media/Expo26.jpeg"),
  Expo27: require("../assets/images/media/Expo27.jpeg"),
  Expo28: require("../assets/images/media/Expo28.jpeg"),
  Expo29: require("../assets/images/media/Expo29.jpeg"),
  Expo30: require("../assets/images/media/Expo30.jpeg"),

  Expo31: require("../assets/images/media/Expo31.JPG"),
  Expo32: require("../assets/images/media/Expo32.JPG"),
  Expo33: require("../assets/images/media/Expo33.JPG"),
  Expo34: require("../assets/images/media/Expo34.JPG"),
  Expo35: require("../assets/images/media/Expo35.JPG"),
  Expo36: require("../assets/images/media/Expo36.JPG"),
  Expo37: require("../assets/images/media/Expo37.JPG"),
  Expo38: require("../assets/images/media/Expo38.JPG"),
  Expo39: require("../assets/images/media/Expo39.JPG"),
  Expo40: require("../assets/images/media/Expo40.JPG"),
  Expo41: require("../assets/images/media/Expo41.JPG"),
  Expo42: require("../assets/images/media/Expo42.JPG"),

  //contact
  wheatImg: require("../assets/images/wheat-img.png"),
  bigLeaf: require("../assets/images/big-leaf.png"),
  dottedshape: require("../assets/images/dottedline-shape.png"),

  // research
  moongdal: require("../assets/images/moongDal.jpg"),

  // Testimonials
  Testimonial: require("../assets/images/advantage.jpg"),

  // fruits section
  fruitContentImg: require("../assets/images/fruits/fruits-bg.jpg"),
  fruitRural: require("../assets/images/fruits/fruitRuler.jpg"),
  shapeCloud: require("../assets/images/fruits/shape.png"),
  orangeIcon: require("../assets/images/fruits/orange.png"),

  pulseIcon: require("../assets/images/rice.png"),

  //seed
  seed: require("../assets/images/vertical-closeup-shot-female-hands-holding-mixed-beans_181624-28689.avif"),

  testimonial: require("../assets/images/testimonial.png"),

  cropMonitoring: require("../assets/images/fruits/cropMonitoring.jpeg"),

  // FRUIT DIVISION
  // FruitDivision1: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-01.png"),
  // FruitDivision2: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-02.png"),
  // FruitDivision3: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-03.png"),
  // FruitDivision4: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-04.png"),
  // FruitDivision5: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-05.png"),
  // FruitDivision6: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-06.png"),

  FruitDivision1: require("../assets/images/fruits/FruitDivision/FruitDivision1.jpeg"),
  FruitDivision2: require("../assets/images/fruits/FruitDivision/FruitDivision2.jpeg"),
  FruitDivision3: require("../assets/images/fruits/FruitDivision/FruitDivision3.jpeg"),
  FruitDivision4: require("../assets/images/fruits/FruitDivision/FruitDivision4.jpeg"),
  FruitDivision5: require("../assets/images/fruits/FruitDivision/FruitDivision5.jpeg"),
  FruitDivision6: require("../assets/images/fruits/FruitDivision/FruitDivision6.jpeg"),

  FruitDivision7: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-07.png"),
  FruitDivision8: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-08.png"),
  FruitDivision9: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-09.png"),
  // FruitDivision10: require("../assets/images/fruits/FruitDivision/ABhitaagrPPT-10.png"),
};

export { IMAGES };
