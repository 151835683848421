import React from "react";
import "../assets/styles/Research.css";
import { IMAGES } from "../utils";
import { RiLeafFill } from "react-icons/ri";

function Research() {
  return (
    <>
      <div>
        <section className="research-tab-bg">
          <div className="research-bg-text">
            <h3>Research</h3>
            {/* <h2>We Are Provide</h2> */}
          </div>
        </section>
        <div className="research-wrapper">
          <section className="pulseProduct">
            <div className="container">
              <h3 className="research-title"> Abhita Agro Industries</h3>
              <div className="research-devider"></div>
              <div className="research-cards-wrapper">
                <div className="research-title">
                  <h3>Pulses</h3>
                </div>
                <hr className="research-hr" />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="research-card-details">
                      <div className="r-name">
                        <p>Moong</p>
                        <span>Akash Patil</span>
                      </div>
                      <div className="research-img">
                        <img src={IMAGES.moongdal} alt="Moong dal" />
                      </div>
                      <ul className="r-product-info">
                        <li>
                          <RiLeafFill className="leaf-icon" />
                          <p>
                            Crop Duration <span>100-100 days</span>
                          </p>
                        </li>
                        <li>
                          {" "}
                          <RiLeafFill className="leaf-icon" />
                          <p>Suitable in all places</p>
                        </li>
                        <li>
                          <RiLeafFill className="leaf-icon" />
                          <p>
                            Grain Color <span>Green</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="research-card-details">
                      <div className="r-name">
                        <p>Moong</p>
                        <span>Akash Patil</span>
                      </div>
                      <div className="research-img">
                        <img src={IMAGES.moongdal} alt="Moong dal" />
                      </div>
                      <ul className="r-product-info">
                        <li>
                          <RiLeafFill className="leaf-icon" />
                          <p>
                            Crop Duration <span>100-100 days</span>
                          </p>
                        </li>
                        <li>
                          {" "}
                          <RiLeafFill className="leaf-icon" />
                          <p>Suitable in all places</p>
                        </li>
                        <li>
                          <RiLeafFill className="leaf-icon" />
                          <p>
                            Grain Color <span>Green</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="research-card-details">
                      <div className="r-name">
                        <p>Moong</p>
                        <span>Akash Patil</span>
                      </div>
                      <div className="research-img">
                        <img src={IMAGES.moongdal} alt="Moong dal" />
                      </div>
                      <ul className="r-product-info">
                        <li>
                          <RiLeafFill className="leaf-icon" />
                          <p>
                            Crop Duration <span>100-100 days</span>
                          </p>
                        </li>
                        <li>
                          {" "}
                          <RiLeafFill className="leaf-icon" />
                          <p>Suitable in all places</p>
                        </li>
                        <li>
                          <RiLeafFill className="leaf-icon" />
                          <p>
                            Grain Color <span>Green</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Research;
