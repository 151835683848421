import React from "react";
import { IMAGES } from "../utils";
import "../assets/styles/Aboutus.css";
import "../assets/styles/mediaqueris/AboutusMedia.css";

function Aboutus() {
  return (
    <>
      <div className="about-slider-bg">
        <div>
          <h3 className="aboutus-slider-text">About Us</h3>
          <p>Harvesting Quality, Nurturing Purity</p>
        </div>
      </div>
      <div className="aboutus-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 ">
              <div className="aboutus-text">
                <img src={IMAGES.abouttext} className="about-text-img" />
                {/* <img src={IMAGES.dottedshape} className="aboutus-dotted-img" /> */}
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
              <div className="about-content-col-wrapper">
                <h4 className="aboutus-title">About Us</h4>
                <h3 className="aboutus-sub-heading">
                  Provide Exceptional Services To A Wide Range
                </h3>
                <p>
                  <strong>Abhita Agro Industries</strong> cultivates Fruits,
                  Vegetables, and Pulses on its 700 acres of private land. The
                  goods produced by <strong>Abhita Agro Industries</strong> are
                  clean. This company grows all of its fruits, vegetables, and
                  pulses organically, and we are delighted with the excellent
                  quality of our products. Our product does not have any adverse
                  consequences. The export of goods has a significant role in
                  international trade and economic growth. These include how
                  exports contribute to a nation's ability to reflect on itself
                  and a nation's ability to reflect on itself, how they boost
                  its economy and contribute to job creation. Businesses have
                  the chance to grow into new markets and customers by exporting
                  their goods. It helps to create when items are exported to
                  meet the wants of the majority of product-exporting economies'
                  citizens. Exporting goods helps a nation's economy, its
                  relations with other nations, and the advancement of
                  innovation and technology. Governments and organizations in
                  this country support and promote product exports since they
                  are crucial to the expansion and development of the world
                  economy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <img src={IMAGES.grasssbg} className="grass-bg" />{" "} */}
        </div>
      </div>
      {/* Advantages  */}
      <div className="advantages">
        <div className="advantage-section">
          <div className="advantage-wrapper">
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="advantage-right">
                  <div className="advantage-right-content">
                    <div className="advantage-heading">
                      <h3>Abhita Agro Industries Pvt Ltd </h3>
                      <h3 className="advantage">ADVANTAGES</h3>
                    </div>
                    <div className="advantage-text">
                      <p>
                        Abhita Agro Industries Pvt Ltd offers several advantages
                        to its customers.we prioritize quality and ensure that
                        our products meet the highest standards. We use advanced
                        manufacturing techniques and adhere to strict quality
                        control measures to deliver premium products to our
                        clients. Abhita Agro Industries Pvt Ltd offers
                        advantages such as quality products, sustainability,
                        diverse product range, excellent customer service, and a
                        commitment to innovation
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* mission visson */}
      <div className="mission-visson-wrapper">
        <div className="mission-visson-content">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="vission-content">
                  <h3>Vision</h3>
                  <p>
                    To be a well-known and prominent farmer-producer Business
                    dedicated to mindful & sustainable socio-economic
                    development.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="mission-content">
                  <h3>Mission</h3>
                  <p>
                    To collaborate with our family of farmers, customers, staff,
                    and associates as partners in progress. To do this, we will
                    use sustainable and scientific farming practices to produce
                    food that is both fresh and safe for our consumers,
                    increasing farm profitability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Why choose Us */}
      <div className="why-choose-us-relative">
        <div className="">
          <div className="why-choose-us-section">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="wcu-content">
                  <div className="wcu-content-spacing">
                    <div className="wcu-content-title">
                      <h3>Why Choose Us?</h3>
                      <p>
                        Some of our specialties that enable us to be a
                        beneficial business partner for our clients include the
                        following:
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <ul className="wcu-ul">
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>Rich vendor base Proven</p>
                          </li>
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>Wide distribution network</p>
                          </li>
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>Timely delivery of consignments </p>
                          </li>
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>Customer focused approach </p>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="wcu-ul">
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>Capability to meet huge market demands</p>
                          </li>
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>record of timely supplies</p>
                          </li>
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>Competitive prices</p>
                          </li>
                          <li className="d-flex align-items-start">
                            <img
                              src={IMAGES.RiceBullet}
                              alt="RiceBullet"
                              className="rice-bullet"
                            />
                            <p>Easy payment modes</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr className="hr-line" />
                    <div className="history-section">
                      <h3>History</h3>
                      <p>
                        The company is launching many processing plant of Chana
                        ( Dollar) in early 2022 and company is also launching
                        contact farming project of Maharashtra.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="col-right-img">
                  <img src={IMAGES.aboutwcuRightColImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Procuring */}
      <div className="procuring-section">
        <div className="procuring-content">
          <div className="container">
            <h3>Procuring</h3>
            <p>
              Procuring Agents, Warehousing Agents, Packaging Staff, and Sales
              and Marketing Personnel form part of our talented and devoted
              team. Our personnel have been able to give superior quality within
              a stipulated time period thanks to their unwavering efforts and
              in-depth knowledge of the agro business. Before buying any of the
              required parameters, our procurement agents ensure that they
              examine each and every one of them. While our sales and marketing
              people maintain constant contact with our clients so that any
              ideas made by them can be implemented. Regular skill development
              training provided to our workers at regular intervals deserves
              credit for this.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutus;
