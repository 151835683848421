import React from "react";
import "../assets/styles/Footer.css";
import "../assets/styles/mediaqueris/FooterMedia.css";
import { IMAGES } from "../utils";
import { PiGrainsLight } from "react-icons/pi";
import { HiOutlineMail } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
const Footer = () => {
  return (
    <>
      <div className="footer">
        {" "}
        <div className="footer-wrapper">
          {/* <div>
            <img src={IMAGES.footerpng} className="footer-bg-img" />
          </div> */}
          <div className="footer-wrapper-inner">
            <div className="container">
              <div className="row">
                <div className=" col-xl-5 col-lg-4 col-md-12 col-sm-12">
                  <div className="footer-about">
                    <img
                      src={IMAGES.abhitaAgroWhiteLogo}
                      alt="logo"
                      className="footer-logo"
                    />
                    <p>
                      Abhita Agro Industries Provide Exceptional Services To A
                      Wide Range cultivates Fruits, Vegetables, and Pulses and
                      delighted with the excellent quality of our products. The
                      goods produced by Abhita Agro Industries are clean.
                    </p>
                  </div>
                </div>
                {/* <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12"></div> */}
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                  <div className="Footer-menu">
                    <h4 className="mb-3">Quick Links</h4>
                    <ul>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/">Home</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/aboutus">About Us</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/services">Services</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/research">Research</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/media">Media</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/contactus">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-2  col-lg-2 col-md-4 col-sm-7">
                  <div className="Footer-menu">
                    <h4 className="mb-3">Services</h4>
                    <ul>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/pulses">Trading</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/seed">Seed Division</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/contractfarming">Contract Farming</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/importexport">Import Export</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/fruits">Fruits</a>
                      </li>
                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/">Vegetables</a>
                      </li>

                      <li className="d-flex align-items-start">
                        <PiGrainsLight size={25} />
                        <a href="/">Bakery Products</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                  <div className="footer-address">
                    <h4 className="mb-3">Get In Touch</h4>
                    <ul>
                      <li className="d-flex align-items-start">
                        <div>
                          <IoHomeOutline
                            size={25}
                            className="footer-add-icon"
                          />
                        </div>
                        <p>
                          Office No-1210 12th Floor, International Trade Centre,
                          Kamdhenu, Sector-14, Kharghar, Navi Mumbai - 410210
                        </p>
                      </li>
                      <li className="d-flex align-items-center">
                        <div>
                          <FiPhone size={25} className="footer-add-icon" />
                        </div>

                        <p>
                          {" "}
                          <a href="tel://+91 8655869400">+918655869400</a>
                        </p>
                      </li>
                      <li className="d-flex align-items-center">
                        <div>
                          <HiOutlineMail
                            size={25}
                            className="footer-add-icon"
                          />
                        </div>
                        <p>info@abhitaagroindustries.com</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bootom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-8  col-sm-12">
                  <p className="footer-copyright">
                    @ Copyright 2023. Abhita Agro Industries. All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="https://wa.link/8l244o" target="_about">
          <BsWhatsapp className="watsapp-icon" />
        </a>
      </div>
    </>
  );
};

export default Footer;
