import React from "react";
import { IMAGES } from "../utils";
import "../assets/styles/WhoWeAre.css";
import "../assets/styles/mediaqueris/WhoWeAreMedia.css"

function WhoWeAre() {
  return (
    <>
    <div className="who-we-are-section">
      <div className="container">
        {/* <div className="leaf-bg"> </div> */}
        <img src={IMAGES.leafBg} className="leaf-bg" />
      
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-12  col-sm-12">
              <div className="who-we-are-left-section">
                <div className="who-we-are-bg-img">
                  <img src={IMAGES.whoweare} alt="Who we are" />
                </div>
                <div className="who-we-are-upper-img">
                  <img src={IMAGES.whowearesmall} alt="Who we are upper" />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-7 col-md-12  col-sm-12">
              <div className="who-we-are-right-section">
                <h2>Welcome To Abhita Agro</h2>
                <h3>Who We Are</h3>
                <div className="wwr-devider"></div>
                <p>
                  We are Delivering Quality is a cutting-edge product designed
                  to ensure excellence in every aspect of the customer
                  experience. From start to finish, our product is committed to
                  maintaining the highest standards, guaranteeing satisfaction
                  at every step. With a focus on precision and attention to
                  detail, we meticulously craft our offerings to surpass
                  expectations. Our team of experts works tirelessly to deliver
                  products and services that not only meet but exceed industry
                  standards.
                </p>
                <p>
                  {" "}
                  We understand that quality is paramount, and that is why we
                  consistently strive to go above and beyond, providing our
                  customers with nothing short of excellence. With Delivering
                  Quality, you can trust that every interaction, every product,
                  and every experience will be of the utmost caliber, leaving a
                  lasting impression of superiority and satisfaction.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12">
              <div className="who-we-are-card">
                <div className="card-who-we-are-first">
                  <div>
                    <h3>Natural Farming</h3>
                    <p>Harmony in diversity, the natural way.</p>
                  </div>
                </div>
                <div className="card-who-we-are-second">
                  <div>
                    <h3>Quality Products</h3>
                    <p>Where Good Agriculture Yields Great Products.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </>
  );
}

export default WhoWeAre;
