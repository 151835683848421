import React from "react";
import "../assets/styles/DroneDivison.css";
import "../assets/styles/mediaqueris/FruitsMedia.css";
import { IMAGES } from "../utils";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

const imgUrls = [
  { img: IMAGES.fruit1 },
  // { img: IMAGES.fruit2 },
  { img: IMAGES.fruit3 },
  // { img: IMAGES.fruit4 },
  { img: IMAGES.fruit5 },
  { img: IMAGES.fruit6 },
  // { img: IMAGES.fruit7 },
  // { img: IMAGES.fruit8 },
  { img: IMAGES.fruit9 },
  { img: IMAGES.fruit10 },
];

function DroneDivision() {
  return (
    <>
      <section className="drone-tab-bg">
        <div className="drone-bg-text">
          <h3>Digital Farming</h3>
        </div>
      </section>
      <div className="drone-content-outer">
        <div className="drone-content-inner">
          <div className="fruit-title">
            <h3>Drone</h3>
            <div className="fruit-devider fh-devider"></div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <h3 className="drone-title">Soil And Field Analysis</h3>
              <p className="drone-content">
                Drone data can be used to isolate soil qualities, for instance,
                Temperature, moisture, slope, elevation and more, enabling all
                the more right soil testing and the season of more sensible
                seeding fixes by making 3D maps
              </p>
              <h3 className="drone-title">Crop Health Assessment</h3>
              <p className="drone-content">
                It's fundamental to survey trim well-being and spot bacterial or
                contagious diseases on trees.
              </p>{" "}
              <h3 className="drone-title">Agricultural Crop Spraying</h3>
              <p className="drone-content">
                The primary issue with the conventional crop spraying is that
                the pesticide usage efficiency is low. Just 40% of pesticide is
                utilized adequately and the 60% of pesticide can't control the
                pest and causes a considerable measure of contamination on
                nature.
              </p>
              <h3 className="drone-title">Irrigation</h3>
              <p className="drone-content">
                Drones with thermal cameras can give fantastic knowledge into
                water system by featuring regions that have pooling water or
                lacking soil dampness.
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12"></div>
          </div>
        </div>
      </div>
      <div className="fh-outer">
        <div className="container">
          <h3 className="fh-title">Crop Monitoring</h3>
          <div className="fh-devider"></div>
          <div className="fh-content-wrapper">
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-12 col-xs-12">
                <p className="p-lg-5 pb-md-5 p-0">
                  Immense fields and low proficiency in edit observing together
                  make cultivating's biggest hindrance. Checking challenges are
                  exacerbated by progressively eccentric climate conditions,
                  which drive hazard and field support costs. The conventional
                  technique for crops exploring includes manual strolling many
                  sections of land to test the yield well-being which isn't so
                  precise. Beforehand, satellite symbolism offered the most
                  progressive type of checking. However, there were downsides.
                  Pictures must be requested ahead of time, could be taken just
                  once every day, and were loose.
                </p>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-12 col-xs-12">
                <div className="fruit-rural-wrapper">
                  <div className="fruit-rural">
                    <img src={IMAGES.cropMonitoring} alt="Fruit rural" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DroneDivision;
