import React from "react";
import "../assets/styles/ContactUs.css";
import "../assets/styles/mediaqueris/ContactUsMedia.css";
import { MdCall, MdLocationOn, MdEmail } from "react-icons/md";
import { IMAGES } from "../utils";
function ContactUs() {
  return (
    <>
      <div>
        <div className="contact-wrapper">
          <section className="contact-tab-bg">
            <div className="contact-bg-text">
              <h3>Contact Us</h3>
              <p>
                We will be the leading company in the national market with each
                of our products Caribbean.
              </p>

              {/* <h2>We Are Provide</h2> */}
            </div>
          </section>
          <section className="contact-form-outer">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="contact-form-col">
                    <div className="contact-form-heading">
                      <p>Have Questions?</p>
                      <h3>Send us a Message</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="m-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="m-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="m-2">
                          <select
                            type="text"
                            className="form-select"
                            placeholder="service"
                          >
                            <option>-select service- </option>
                            <option>Fruits </option>
                            <option>Vegetables </option>
                            <option>Bakery Products</option>
                            <option>Meat Products</option>
                            <option>Pulses Products</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="m-2">
                          <textarea
                            type=""
                            className="form-control"
                            placeholder="Message"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn-get-in-touch">
                      <button>Get In Touch</button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="contact-details-outer">
                    <div className="contact-details-heading">
                      <h3>Contact Information</h3>
                      <p>
                        Plan upon yet way get cold spot its week. Almost do am
                        or limits hearts. Resolve parties but why she shewing.
                      </p>
                    </div>
                    <div className="contact-details-inner">
                      <div>
                        <MdCall className="call-icon" />
                      </div>
                      <div className="contact-details">
                        <h3>Contact No.</h3>
                        <p>+918655869400</p>
                      </div>
                    </div>
                    <div className="contact-details-inner">
                      <div>
                        <MdLocationOn className="location-icon" />
                      </div>
                      <div className="contact-details">
                        <h3>Our Location</h3>
                        <p>
                          Office No-1210 12th Floor, International Trade Centre,
                          Kamdhenu, Sector-14, Kharghar, Navi Mumbai - 410210
                        </p>
                      </div>
                    </div>
                    <div className="contact-details-inner">
                      <div>
                        <MdEmail className="email-icon" />
                      </div>
                      <div className="contact-details">
                        <h3>Official Email</h3>
                        <p>info@abhitaagroindustries.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
