import React from "react";
import "../assets/styles/Services.css";
import "../assets/styles/mediaqueris/ServiceMedia.css";
import { IMAGES } from "../utils/index";
import { MdArrowRightAlt } from "react-icons/md";
function Services() {
  return (
    <>
      <div className="service-tab-outer">
        <section className="service-tab-bg">
          <div className="service-bg-text">
            <h3>Services</h3>
            {/* <h2>We Are Provide</h2> */}
          </div>
        </section>
        <section className="service-products-section">
          <h3>Services We Provide</h3>
          <div className="hww-devider"></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/contractfarming" className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img
                        src={IMAGES.contractFarming}
                        className="service-cards-img"
                      />
                    </div>
                    <div className="service-card-text">
                      <h3>Contract Farming</h3>
                      <p>
                        Abhita Agro Industries Pvt to empower farmers in
                        Maharashtra through contract farming.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/pulses" className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img src={IMAGES.pulse} className="service-cards-img" />
                    </div>
                    <div className="service-card-text">
                      <h3>Trading</h3>
                      <p>
                        Exports of premium pulses products are available from
                        your top source!
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/seed" className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img
                        src={IMAGES.seedDivision}
                        className="service-cards-img"
                      />
                    </div>
                    <div className="service-card-text">
                      <h3>Seed Division</h3>
                      <p>
                        This division is responsible for researching and
                        breeding new seed
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/importexport" className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img
                        src={IMAGES.importExport}
                        className="service-cards-img"
                      />
                    </div>
                    <div className="service-card-text">
                      <h3>Import Export</h3>
                      <p>
                        Crucial components of international trade, facilitating
                        the exchange of goods and services between countries.
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/fruits" className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img src={IMAGES.fruits} className="service-cards-img" />
                    </div>
                    <div className="service-card-text">
                      <h3>Fruit division</h3>
                      <p>
                        Our Fruits Product line offers a delectable assortment
                        of fresh, High-quality fruits.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/dronedivision" className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img
                        src={IMAGES.droneDivision}
                        className="service-cards-img"
                      />
                    </div>
                    <div className="service-card-text">
                      <h3>Drone Farming</h3>
                      <p>
                        Flying robots help farmers! Drones scout crops, spray
                        them precisely, and even plant seeds, boosting
                        efficiency.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/fruits"  className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img
                        src={IMAGES.vegetables}
                        className="service-cards-img"
                      />
                    </div>
                    <div className="service-card-text">
                      <h3>Vegetables</h3>
                      <p>
                        Vegetables, which are high in fibre, aid in digestion
                        and support a healthy weight.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <a href="/fruits"  className="service-page-link">
                  <div className="service-cards">
                    <div>
                      <img src={IMAGES.bakery} className="service-cards-img" />
                    </div>
                    <div className="service-card-text">
                      <h3>Bakery Products</h3>
                      <p>
                        Bakery products are a delightful addition to any bakery
                        menu, Adding Richness to a variety of baked goods.
                      </p>
                    </div>
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </section>
        <section className="how-we-work-section">
          {/* <div className="how-we-work-title-div">
            <div className="how-we-work-heading">
              <h3>How We Work</h3>
              <div className="hww-devider"></div>
              <p>
                I believe that agriculture land - productive agricultural land
                with water on site - will be valuable in the future.
              </p>
            </div>
          </div> */}
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="how-we-work-col">
                  <div>
                    <img src={IMAGES.payment} className="step-img-icon" />
                  </div>
                  <div className="how-we-work-text">
                    <h3>Step 01</h3>
                    <p>
                      Augue a molestie elementum malesuada lorem quam,
                      sollicitudin placerat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="how-we-work-col">
                  <div>
                    <img src={IMAGES.payment} className="step-img-icon" />
                  </div>
                  <div className="how-we-work-text">
                    <h3>Step 02</h3>
                    <p>
                      Augue a molestie elementum malesuada lorem quam,
                      sollicitudin placerat.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="how-we-work-col">
                  <div>
                    <img src={IMAGES.payment} className="step-img-icon" />
                  </div>
                  <div className="how-we-work-text">
                    <h3>Step 03</h3>
                    <p>
                      Augue a molestie elementum malesuada lorem quam,
                      sollicitudin placerat.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="how-we-work-col">
                  <div>
                    <img src={IMAGES.payment} className="step-img-icon" />
                  </div>
                  <div className="how-we-work-text">
                    <h3>Step 04</h3>
                    <p>
                      Augue a molestie elementum malesuada lorem quam,
                      sollicitudin placerat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="container hww-bottom-wrapper">
            <div className="row">
              <div className="col-lg-6">
                <h3 className="hww-heading">
                  {" "}
                  We Can Create an Environment That’s Beyond Your Imagination.{" "}
                </h3>
              </div>
              <div className="col-lg-6">
                <p className="hww-text">
                  Our employees are the backbone of our company. Lawnella has an
                  ongoing employee training program that includes a regular
                  schedule of seminars and information updates.
                </p>
                <div className="btn-reach-out">
                  <a href="https://wa.link/8l244o">
                    Reach Out to us <MdArrowRightAlt size={25} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Services;
