import React from "react";
import "../assets/styles/Counter.css";
import "../assets/styles/mediaqueris/CounterMedia.css";
import { IMAGES } from "../utils";

function Counter() {
  return (
    <>
      <div className="counter-wrapper">
        {/* <div>
          <img src={IMAGES.flower} className="counter-flower-img" />
        </div> */}
        <div className="couter-outer-section">
          <div className="container">
            <div className="couter-inner-section">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                  <div className="achivements">
                    {/* <p>ACHIVEMENTS</p> */}
                    <h3 className="pt-2">ACHIVEMENTS</h3>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                  <div className="counts">
                    <h3>100+</h3>
                    <p> Widespread Distribution</p>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12">
                  <div className="counts">
                    <h3>99%</h3>
                    <p>Happy clients</p>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 ">
                  <div className="counts">
                    <h3>100%</h3>
                    <p>Productive Crop Yield</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Counter;
