import React from "react";
import "../../assets/styles/Pulses.css";
import { IMAGES } from "../../utils";
function Pulses() {
  return (
    <>
    <div>
      <section className="pulse-tab-bg">
        <div className="pulse-bg-text">
          <h3>Trading</h3>
        </div>
      </section>
      <div className="pulses-outer-section">
        <div className="container">
          {/* <div className="row"> */}
          {/* <div className="col-xl-5 col-lg-4 col-md-6 col-sm-12"> */}

          {/* </div> */}
          {/* <div className="col-xl-7 col-lg-4 col-md-6 col-sm-12"> */}
          <div className="pulses-content-list-wrapper">
            <h3>View our extensive selection of pulse goods</h3>
            <div className="pulses-content">
              <p>
                Exports of premium pulse products are available from your top
                source! We are committed to giving you access to the world's
                best and healthiest pulses. We are your go-to partner for all
                your pulse import needs thanks to our dedication to quality,
                sustainability, and customer satisfaction.
              </p>
            </div>
            <div className="pulses-content-ul-wrappaer">
              <ul className="pulses-content-list">
                <li>
                  {" "}
                  {/* <MdOutlineCheck size={22} className="thumbsup-icon" /> */}
                  <img src={IMAGES.pulseIcon} className="pulseIcon" />
                  <p>Premium Quality</p>
                </li>
                <li>
                  {" "}
                  {/* <MdOutlineCheck size={22} className="thumbsup-icon" /> */}
                  <img src={IMAGES.pulseIcon} className="pulseIcon" />
                  <p>Diverse Range</p>
                </li>
                <li>
                  {" "}
                  {/* <MdOutlineCheck size={22} className="thumbsup-icon" /> */}
                  <img src={IMAGES.pulseIcon} className="pulseIcon" />
                  <p>Global Sourcing</p>
                </li>
                <li>
                  {" "}
                  {/* <MdOutlineCheck size={22} className="thumbsup-icon" /> */}
                  <img src={IMAGES.pulseIcon} className="pulseIcon" />
                  <p>Sustainable Practices</p>
                </li>
              </ul>
              <ul className="pulses-content-list">
                <li>
                  {" "}
                  {/* <MdOutlineCheck size={22} className="thumbsup-icon" /> */}
                  <img src={IMAGES.pulseIcon} className="pulseIcon" />
                  <p>Customized Solutions</p>
                </li>
                <li>
                  {" "}
                  {/* <MdOutlineCheck size={22} className="thumbsup-icon" /> */}
                  <img src={IMAGES.pulseIcon} className="pulseIcon" />
                  <p>Stringent Quality Control</p>
                </li>
                <li>
                  {" "}
                  {/* <MdOutlineCheck size={22} className="thumbsup-icon" /> */}
                  <img src={IMAGES.pulseIcon} className="pulseIcon" />
                  <p>Customer Satisfaction</p>
                </li>
              </ul>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  </>
  );
}

export default Pulses;
