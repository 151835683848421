import React from "react";
import "../../assets/styles/ContractFarming.css";
import { IMAGES } from "../../utils";
function ContractFarming() {
  return (
    <>
      <div>
        <section className="cf-tab-bg">
          <div className="cf-bg-text">
            <h3>Contract Farming</h3>
          </div>
        </section>
        <div className="cfs-outer-section">
          <div className="container">
            <h3 className="cf-title">Contract Farming</h3>
            <div className="cf-devider"></div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="cfs-content">
                  <p>
                    Abhita Agro Industries Pvt Ltd to empower farmers in
                    Maharashtra through contract farming. Ltd. cooperated with
                    The objective was to promote alternative livelihoods through
                    sustainable cultivation of soybean and tur crops. Under this
                    pilot project, 20 farmers of Buldana district planted
                    soybean and tur crops in their fields. Before planting
                    soybeans and tur, the company conducts soil and water
                    testing to assess the suitability of the crop for growth.
                    Farmers were also given proper training in land preparation,
                    tillage, harvesting and packaging. Vehicles were also
                    provided for transporting seeds, packing materials and
                    materials. This collaboration enabled market linkage, which
                    ensured the company's products were bought and sold. After
                    understanding the concept, the farmers agreed to try this on
                    their land. About 300 acres of land were brought under
                    soybean and tur cultivation on an experimental basis.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="cf-division-img">
                  <img src={IMAGES.pulse} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractFarming;
