import React from "react";
import "../../assets/styles/SeedDivision.css";
// import "../../assets/styles/mediaqueris/FruitsMedia.css";
import { IMAGES } from "../../utils";
import { MdOutlineCheck } from "react-icons/md";

import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

const imgUrls = [
  { img: IMAGES.package1 },
  { img: IMAGES.package2 },
  // { img: IMAGES.package3 },
  { img: IMAGES.package4 },
  { img: IMAGES.package5 },
  { img: IMAGES.package6 },
  { img: IMAGES.package7 },
  { img: IMAGES.package8 },
  // { img: IMAGES.package9 },
  // { img: IMAGES.package10 },
  { img: IMAGES.package11 },
  { img: IMAGES.package12 },
  { img: IMAGES.package13 },
  // { img: IMAGES.package14 },
  // { img: IMAGES.package15 },
];

function SeedDivision() {
  return (
    <>
      <div>
        <section className="seed-tab-bg">
          <div className="seed-bg-text">
            <h3>Seed Division</h3>
          </div>
        </section>
        <div className="seeds-outer-section">
          <div className="container">
            <h3 className="seed-title">Seed Division</h3>
            <div className="seed-devider"></div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="seeds-content">
                  <p>
                    The seed division is an essential component of the
                    agriculture industry, focused on the development,
                    production, and distribution of seeds for various crops.
                    This division is responsible for researching and breeding
                    new seed varieties that exhibit improved traits such as
                    higher yields, disease resistance, and better adaptability
                    to different climates. Once developed, these seeds are
                    carefully produced and packaged for sale to farmers and
                    other agricultural stakeholders. The seed division also
                    plays a crucial role in ensuring seed quality through
                    testing, certification, and compliance with regulatory
                    standards. By providing high-quality seeds, the seed
                    division contributes significantly to global food security,
                    sustainable agriculture practices, and the continuous
                    advancement of the farming sector.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="seed-division-img">
                  <img src={IMAGES.pulse} />
                </div>
              </div>
            </div>
            <div>
              <div className="text-center pt-5">
                <div className="">
                  <h3 className="seed-title">Product</h3>
                </div>
              </div>
              <div className="row">
                <Gallery>
                  {imgUrls.map((item, index) => (
                    <div className="col-xl-3 col-lg-4 col-md-6  col-sm-12">
                      <Item
                        original={item.img}
                        thumbnail={item.img}
                        width="800"
                        height="600"
                      >
                        {({ ref, open }) => (
                          <div className="media-img-col-wrapper">
                            <div className="media-img-col">
                              <img
                                ref={ref}
                                onClick={open}
                                src={item.img}
                                alt={`Image ${index + 1}`}
                              />
                            </div>
                          </div>
                        )}
                      </Item>
                    </div>
                  ))}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
        <div className="catlogue_section">
          <div className="catlogue_section_outer">
            <div className="catlogueFirst">
              <a href="https://onedrive.live.com/?authkey=%21AKqS9dX6x6SsG84&id=6518F385BC065C0F%21297&cid=6518F385BC065C0F&parId=root&parQt=sharedby&o=OneUp">
                Seed Division
              </a>
            </div>
            <p>Click here ..</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeedDivision;
