import React from "react";
import "../../assets/styles/ImportExport.css";
import { IMAGES } from "../../utils";
function ImportExport() {
  return (
    <>
      <div>
        <section className="ipxp-tab-bg">
          <div className="ipxp-bg-text">
            <h3>Import Export</h3>
          </div>
        </section>
        <div className="ipxps-outer-section">
          <div className="container">
            <h3 className="ipxp-title">Import Export</h3>
            <div className="ipxp-devider"></div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="ipxps-content">
                  <p>
                    Export and import are crucial components of international
                    trade, facilitating the exchange of goods and services
                    between countries. In the realm of exports, various products
                    are shipped to foreign markets, benefiting domestic
                    economies and generating revenue. Common export products
                    include agricultural commodities like Fruits, Vegetables,
                    Bakery Product, Pulses. On the other hand, imports represent
                    goods and services purchased from other countries,
                    fulfilling local demand and supplementing domestic
                    production. Import products encompass a wide range, such as
                    crude oil, consumer electronics, apparel, vehicles, and
                    chemicals. The export and import sector is essential to the
                    development of the world economy, international cooperation,
                    and cross-cultural dialogue.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="ipxp-division-img">
                  <img src={IMAGES.pulse} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportExport;
