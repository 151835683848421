import React from "react";
import "../assets//styles/ServiceHome.css";
import "../assets/styles/mediaqueris/ServiceHomeMedia.css";
import { IMAGES } from "../utils";

function ServicesHome() {
  const products = [
    {
      title: "Fruits",
      desc: "Our Fruits Product line offers a delectable assortment of fresh, high-quality fruits sourced directly from trusted farmers and orchards..",
    },
    {
      title: "Vegetables",
      desc: "Providing a vast variety of vitamins, minerals, and antioxidants that are crucial for preserving a healthy body and mind..",
    },
    {
      title: "Bakery Product",
      desc: "Dairy products in bakery products are a delightful addition to any bakery menu, adding richness, flavor and versatility to a variety of baked goods. A popular bakery milk..",
    },
  ];
  return (
    <>
      <div className="home-service-outer-wrpper">
        <div className="home-service-inner-wrapper">
          <div className="container home-service-container pt-5">
            <h3 className="our-service">Our Services</h3>
            <div className="home-service-heading">
              <h3>Healthy Life With Fresh Products</h3>
            </div>
          </div>

          <div className="container">
            <div className="service-list-card-outer">
              <div className="service-list-card">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <a href="/pulses" className="service-list-link">
                      <div className="service-list-outer">
                        <div className="service-list">
                          <img src={IMAGES.fruit} className="fruit-icon" />
                          <h3>Trading</h3>
                          <p>
                            Exports of premium pulses products are available
                            from your top source!
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <a href="/seed" className="service-list-link">
                      <div className="service-list-outer">
                        <div className="service-list">
                          <img
                            src={IMAGES.vegetableIcon}
                            className="fruit-icon"
                          />
                          <h3>Seed Division</h3>
                          <p>
                            This division is responsible for researching and
                            breeding new seed
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <a href="/contractfarming" className="service-list-link">
                      <div className="service-list-outer">
                        <div className="service-list">
                          <img
                            src={IMAGES.agriculture}
                            className="fruit-icon"
                          />
                          <h3>Contract Farming</h3>
                          <p>
                            Abhita Agro Industries Pvt to empower farmers in
                            Maharashtra through contract farming.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <a href="/importexport" className="service-list-link">
                      <div className="service-list-outer">
                        <div className="service-list">
                          <img src={IMAGES.export} className="fruit-icon" />
                          <h3>Import Export</h3>
                          <p>
                            Crucial components of international trade,
                            facilitating the exchange of goods and services
                            between countries
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <a href="/fruits" className="service-list-link">
                      <div className="service-list-outer">
                        <div className="service-list">
                          <img src={IMAGES.fruit} className="fruit-icon" />
                          <h3>Fruit Division</h3>
                          <p>
                            We provide you with the garden fresh and most
                            delicious fruits from around the world.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <a href="/dronedivision" className="service-list-link">
                      <div className="service-list-outer">
                        <div className="service-list">
                          <img src={IMAGES.drone} className="fruit-icon" />
                          <h3>Digital Farming</h3>
                          <p>
                          Flying robots help farmers! Drones scout crops, 
                          spray them precisely, and even plant seeds, 
                          boosting efficiency.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="service-list-outer">
                      <div className="service-list our-main-service-bg">
                        <img src={IMAGES.ourservice} className="fruit-icon" />
                        <h3>Our Main Services</h3>
                        <p>
                          Which include fruits, vegetables, and pulses
                          organically ..
                        </p>

                        <div className="btn-main-service-outer">
                          <a
                            className="btn-main-service-inner"
                            href="/services"
                          >
                            <span className="arrow"></span>
                            <span className="text"> Our Service</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesHome;
