import React, { useEffect, useState } from "react";
import "../assets/styles/Header.css";
import "../assets/styles/mediaqueris/HeaderMedia.css";
import { IMAGES } from "../utils";
import { BiMenuAltRight } from "react-icons/bi";
import {
  PiPhoneCallBold,
  PiInstagramLogoFill,
  PiPinterestLogoFill,
} from "react-icons/pi";
import { MdOutlineEmail, MdFacebook, MdClose } from "react-icons/md";
import { TfiTwitterAlt } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
function Header() {
  const [show, setShow] = useState(false);
  const controlNavbar = () => {
    if (window.scrollY > 250) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <>
      <div className="header-wrapper">
        <div className={`top-header-section ${show && "hideTopBar"}`}>
          <div className="top-header-inner">
            <div className="top-header-section-content">
              <div className="top-header-list-section">
                <ul>
                  <li>
                    <a href="tel://+91 8655869400">
                      <PiPhoneCallBold size={20} className="top-contact-icon" />
                      <p>+918655869400</p>
                    </a>
                  </li>
                  <li>
                    <MdOutlineEmail size={20} className="top-contact-icon" />
                    <p>info@abhitaagroindustries.com</p>
                  </li>
                </ul>
              </div>

              <div className="social-icon-section">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/abhitaagroindustries"
                      target="_blank"
                    >
                      <MdFacebook size={25} className="top-social-icons" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <PiInstagramLogoFill
                        size={25}
                        className="top-social-icons"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <PiPinterestLogoFill
                        size={25}
                        className="top-social-icons"
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <TfiTwitterAlt size={25} className="top-social-icons" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`main-nav-wrapper ${show && "fixed-nav-bar"}`}>
          <div className={`nav-header-section  ${show && "fixed-menu-items"}`}>
            <div className="menu-items">
              <div className="mb-menu-logo-bg">
                <a className="" href="/">
                  <img
                    src={IMAGES.abhitaAgroColorLogo}
                    alt="Abhita Agro Logo"
                    className="abhitaAgroLogo"
                  />
                </a>
              </div>
              <div className="logo-bg-white-wrapper">
                <img
                  src={IMAGES.shape}
                  alt="Logo bg dark shape"
                  className="logo-dark-bg"
                />
              </div>
              <div className="menu-list-div">
                <div>
                  <BiMenuAltRight
                    size={30}
                    className="menu-btn"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTop"
                    aria-controls="offcanvasTop"
                  />
                </div>
                <ul className="menu-list-items mb-menu-hide">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/aboutus">About Us</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  {/* <li>
                    <a href="/research">Research</a>
                  </li> */}
                  <li>
                    <a href="/media">Media</a>
                  </li>
                  <li>
                    <a href="/Blog">Blog</a>
                  </li>

                  <li>
                    <a href="/contactus">Contact Us</a>
                  </li>
                </ul>

                {/* <div class="dropdown">
                  <button class="dropbtn">भाषा</button>
                  <div class="dropdown-content">
                    <a href="#">English</a>
                    <a href="#">मराठी</a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile view offcanvas */}

      <div
        className="offcanvas offcanvas-top"
        tabIndex="-1"
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
      >
        <div className="offcanvas-header">
          <div className="mb-menu-logo-bg">
            <a className="" href="/">
              <img
                src={IMAGES.abhitaAgroColorLogo}
                alt="Abhita Agro Logo"
                className="offcanvas-abhitaAgroLogo"
              />
            </a>
          </div>
          <MdClose
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <ul className="offcanvas-menu-list">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/aboutus">About Us</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/research">Research</a>
            </li>
            <li>
              <a href="/media">Media</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
          </ul>
          {/* <div class="offcanvas-dropdown-mb">
            <button class="offcanvas-dropbtn">भाषा</button>
            <div class="offcanvas-dropdown-content">
              <a href="#">English</a>
              <a href="#">मराठी</a>
            </div>
          </div> */}
          <div className="offcanvas-header-list-section">
            <ul>
              <li>
                <PiPhoneCallBold size={20} className="top-contact-icon" />
                <p>+918655869400</p>
              </li>
              <li>
                <MdOutlineEmail size={20} className="top-contact-icon" />
                <p>info@abhitaagroindustries.com</p>
              </li>
            </ul>
          </div>
          <div className="offcanvas-social-icon-section">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/abhitaagroindustries"
                  target="_blank"
                >
                  <MdFacebook size={25} className="top-social-icons" />
                </a>
              </li>
              <li>
                <a>
                  <PiInstagramLogoFill size={25} className="top-social-icons" />
                </a>
              </li>
              <li>
                <a>
                  <PiPinterestLogoFill size={25} className="top-social-icons" />
                </a>
              </li>
              <li>
                <a>
                  <TfiTwitterAlt size={25} className="top-social-icons" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
