import logo from "./logo.svg";
import "./App.css";
import { Route, Router, Routes } from "react-router-dom";
import Header from "./layouts/Header";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Footer from "./layouts/Footer";
import Services from "./pages/Services";
import Media from "./pages/Media";
import ContactUs from "./pages/ContactUs";
import Research from "./pages/Research";
import Fruits from "./pages/fruits/Fruits";
import SeedDivision from "./pages/seed/SeedDivision";
import ContractFarming from "./pages/contract farming/ContractFarming";
import ImportExport from "./pages/import export/ImportExport";
import Pulses from "./pages/trading/Pulses";
import Blog from "./pages/Blog";
import DroneDivision from "./pages/DroneDivision";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/research" element={<Research />} />
        <Route path="/media" element={<Media />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/fruits" element={<Fruits />} />
        <Route path="/seed" element={<SeedDivision />} />{" "}
        <Route path="/contractfarming" element={<ContractFarming />} />{" "}
        <Route path="/importexport" element={<ImportExport />} />
        <Route path="/pulses" element={<Pulses />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/dronedivision" element={<DroneDivision />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
