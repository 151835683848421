import React from "react";
import { IMAGES } from "../utils";
import "../assets/styles/Testimonial.css";
import "../assets/styles/mediaqueris/TestimonialsMedia.css";
import { RiAccountCircleFill } from "react-icons/ri";

function Testimonial() {
  return (
    <div className="testimonials-section">
      <h3 className="testimonial-heading">What Our Client Says</h3>
      <div className="devider"></div>
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 ">
          <div className="testimonial-bg">
            <div className="container ">
              <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div
                    className="carousel-item active"
                    data-bs-interval="10000"
                  >
                    {/* <img src={IMAGES.advantage} className="testimonial-img" alt="..." /> */}
                    <div className="carousel-caption  d-md-block">
                      {/* <img
                        src={IMAGES.advantage}
                        className="testimonial-img"
                        alt="..."
                      /> */}
                      <RiAccountCircleFill
                        size={80}
                        className="testimonial-img"
                      />
                      <h5 className="testimonial-title">Rohan Kanojia</h5>
                      <p>
                        Abhita Agro Industries has ability to deliver products
                        on time and as promised. Timely shipments and reliable
                        service are essential in the agriculture industry, where
                        perishable goods are often involved
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval="2000">
                    <div className="carousel-caption  d-md-block">
                      {/* <img
                        src={IMAGES.advantage}
                        className="testimonial-img"
                        alt="..."
                      /> */}
                      <RiAccountCircleFill
                        size={80}
                        className="testimonial-img"
                      />
                      <h5 className="testimonial-title">Nihal Shardul</h5>
                      <p>
                        Abhita Agro Industries has ability to deliver products
                        on time and as promised. Timely shipments and reliable
                        service are essential in the agriculture industry, where
                        perishable goods are often involved
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="carousel-caption  d-md-block">
                      {/* <img
                        src={IMAGES.advantage}
                        className="testimonial-img"
                        alt="..."
                      /> */}
                      <RiAccountCircleFill
                        size={80}
                        className="testimonial-img"
                      />
                      <h5 className="testimonial-title">Kirti Bade</h5>
                      <p>
                        Abhita Agro Industries has ability to deliver products
                        on time and as promised. Timely shipments and reliable
                        service are essential in the agriculture industry, where
                        perishable goods are often involved
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <img
            src={IMAGES.testimonial}
            alt=""
            className="testimonials-right-img"
          />
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
