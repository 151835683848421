import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { IMAGES } from "../utils/index";

import "../assets/styles/Media.css";
import "../assets/styles/mediaqueris/MediaMedia.css";
import { MdClose } from "react-icons/md";

const imgUrls = [
  { img: IMAGES.img1 },

  { img: IMAGES.img6 },
  { img: IMAGES.img7 },
  // { img: IMAGES.img8 },
  { img: IMAGES.img9 },
  { img: IMAGES.img10 },
  { img: IMAGES.img11 },

  { img: IMAGES.img14 },

  { img: IMAGES.img18 },
  { img: IMAGES.img19 },
  { img: IMAGES.img3 },
  { img: IMAGES.img4 },

  { img: IMAGES.package1 },
  { img: IMAGES.package2 },
  // { img: IMAGES.package3 },
  { img: IMAGES.package4 },
  { img: IMAGES.package5 },
  { img: IMAGES.package6 },
  { img: IMAGES.package7 },
  { img: IMAGES.package8 },
  // { img: IMAGES.package9 },
  // { img: IMAGES.package10 },
  { img: IMAGES.package11 },
  { img: IMAGES.package12 },
  { img: IMAGES.package13 },
  // { img: IMAGES.package14 },
  // { img: IMAGES.package15 },
  { img: IMAGES.fruit1 },
  { img: IMAGES.fruit2 },
  { img: IMAGES.fruit3 },
  // { img: IMAGES.fruit4 },
  { img: IMAGES.fruit5 },
  { img: IMAGES.fruit6 },
  { img: IMAGES.fruit7 },
  { img: IMAGES.fruit8 },
  { img: IMAGES.fruit9 },
  { img: IMAGES.fruit10 },
  { img: IMAGES.Expo31 },
  { img: IMAGES.Expo32 },
  { img: IMAGES.Expo33 },
  { img: IMAGES.Expo34 },
  { img: IMAGES.Expo35 },
  { img: IMAGES.Expo36 },
  { img: IMAGES.Expo37 },
  { img: IMAGES.Expo38 },
  { img: IMAGES.Expo39 },
  { img: IMAGES.Expo40 },
  { img: IMAGES.Expo41 },
  { img: IMAGES.Expo42 },
];


const Media = () => {
  return (
    <>
      <div className="media-section">
        <section className="media-tab-bg">
          <div className="media-bg-text">
            <h3>Media</h3>
          </div>
        </section>
        <div className="media-wrapper">
          <h3 className="media-heading">Our Stories</h3>
          <div className="container">
            <div>
              <div className="row">
                <Gallery>
                  {imgUrls.map((item, index) => (
                    <div className="col-xl-3 col-lg-4 col-md-6  col-sm-12">
                      <Item
                        original={item.img}
                        thumbnail={item.img}
                        width="800"
                        height="600"
                      >
                        {({ ref, open }) => (
                          <div className="media-img-col-wrapper">
                            <div className="media-img-col">
                              <img
                                ref={ref}
                                onClick={open}
                                src={item.img}
                                alt={`Image ${index + 1}`}
                              />
                            </div>
                          </div>
                        )}
                      </Item>
                    </div>
                  ))}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Media;
